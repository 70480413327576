<template>
    <div class="row mt-60">
      <loader-component v-if="loading"/>
      <div class="col-md-4">
        <div class="c-card">
          <div class="filter-section">
            <form>
              <ul class="filter-list">
                <li>
                  <b-button @click="selectFilter('Month')" class="coll-btn"
                  >{{ filter.quarter?'Q'+filter.quarter:filter.month?filter.month :'Month' }} <i class="fas fa-chevron-down"></i></b-button
                  >
  
                  <!-- Element to collapse -->
                </li>
                <li>
                  <b-button @click="selectFilter('Center')" class="coll-btn"
                  >{{ filter.center || 'All Expense' }} <i class="fas fa-chevron-down"></i></b-button
                  >
                </li>
            
              </ul>
  
              <b-collapse id="collapse-1" v-model="showMonthFilter">
                <ul class="month-selection-list">
                  <li v-for="(item, key) in months" :key="key">
                    <a v-if="!['Q1', 'Q2', 'Q3', 'Q4'].includes(item.text)" href="javascript:void(0)" :class="item.text == filter.month?'active':''" @click="selectPeriod('monthly',item.text); showMonthFilter=false; getReports()">{{ item.text }}</a>
                    <a v-else href="javascript:void(0)" :class="item.value == filter.quarter?'active':''" @click="selectPeriod('quarterly',item.value); showMonthFilter=false; getReports()">{{ item.text }}</a>
                  </li>
                </ul>
              </b-collapse>
              <b-collapse id="collapse-2" v-model="showCenterFilter">
                <ul class="center-selection-list">
                    <li>
                        <a 
                            href="javascript:void(0)" 
                            @click="showAllData = !showAllData; 
                            showCenterFilter=false; 
                            showDmcData=false; 
                            showDlcData=false;
                            showDfoData=false; 
                            showIfoData=false;  
                            getReports()"
                            :class="showAllData == true ?'active':''"
                             
                        >
                            All
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="showDmcData = !showDmcData; 
                            showCenterFilter=false; 
                            showAllData=false; 
                            showDlcData=false;
                            showDfoData=false; 
                            showIfoData=false;  
                            getReports()"
                            :class="showDmcData == true ?'active':''"
                        >
                            Direct Material Consumed
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="showDlcData = !showDlcData; 
                            showCenterFilter=false;
                            showAllData=false; 
                            showDmcData=false;
                            showDfoData=false; 
                            showIfoData=false;"
                            :class="showDlcData == true ?'active':''"
                        >
                            Direct Labour Cost
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="showDfoData = !showDfoData; 
                            showCenterFilter=false;
                            showAllData=false; 
                            showDmcData=false;
                            showDlcData=false; 
                            showIfoData=false;  
                            getReports()"
                            :class="showDfoData == true ?'active':''"
                        >
                            Direct Factory Overheads (FOH)
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" 
                            @click="showIfoData = !showIfoData; 
                            showCenterFilter=false;
                            showAllData=false; 
                            showDmcData=false;
                            showDlcData=false; 
                            showDfoData=false;   
                            getReports()"
                            :class="showIfoData == true ?'active':''"
                        >
                            Indirect Factory Overheads (FOH)
                        </a>
                    </li>
                </ul>
              </b-collapse>
            </form>
  
            
          </div>
          <!-- All Expenses  -->
          <div v-show="showAllData" class="target-content">
            <h2 class="content-title">Cost of Sales</h2>
            <ul>
              <li
                  v-for="item in costs_list"
                  :key="item.id"
                  class="row w-100 m-0"
              >
                
                  <div class="l-label col-8">
                    <label>{{ item.name }}</label>
                  </div>
                  <div class="r-label col-4">
                    <span class="achieved h5">{{ item.target }}</span> /
                    <span class="target h5">{{ item.targetTotal }}</span>
                  </div>
                  <div class="col-12">
                    <b-progress
                        :max="item.targetTotal"
                        height="25px"
                        variant="warning"
                        :precision="0"
                        show-progress="true"
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                        <b-progress-bar :value="item.target">
                            <span>
                        <strong>{{ calculateProgress(item.target, item.targetTotal) }}%</strong></span
                      >
                        </b-progress-bar>
                    </b-progress>
                  </div>
              </li>
            </ul>
          </div>
          <!-- Direct Material Consumed -->
          <div v-show="showDmcData" class="target-content mt-5">
            <h2 class="content-title">Direct Material Consumed</h2>
            <ul>
              <li
                  v-for="item in directMaterialConsumData"
                  :key="item.id"
                  class="row w-100 m-0"
              >
                
                  <div class="l-label col-8">
                    <label>{{ item.name }}</label>
                  </div>
                  <div class="r-label col-4">
                    <span class="achieved h5">{{ item.expenseBudget }}</span> /
                    <span class="target h5">{{ item.totalBudget }}</span>
                  </div>
                  <div class="col-12">
                    <b-progress
                        :max="item.totalBudget"
                        height="25px"
                        variant="warning"
                        :precision="0"
                        show-progress="true"
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                        <b-progress-bar :value="item.expenseBudget">
                            <span>
                        <strong>{{ calculateProgress(item.expenseBudget, item.totalBudget) }}%</strong></span
                      >
                        </b-progress-bar>
                    </b-progress>
                  </div>
                 
              </li>
            </ul>
          </div>
          <!-- Direct Labour Cost -->
          <div  v-show="showDlcData" class="target-content mt-5">
            <h2 class="content-title">Direct Labour Cost</h2>
            <ul>
              <li
                  v-for="item in DirectLabourCost"
                  :key="item.id"
                  class="row w-100 m-0"
              >
                
                  <div class="l-label col-8">
                    <label>{{ item.name }}</label>
                  </div>
                  <div class="r-label col-4">
                    <span class="achieved h5">{{ item.expenseBudget }}</span> /
                    <span class="target h5">{{ item.totalBudget }}</span>
                  </div>
                  <div class="col-12">
                    <b-progress
                        :max="item.totalBudget"
                        height="25px"
                        variant="warning"
                        :precision="0"
                        show-progress="true"
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                        <b-progress-bar :value="item.expenseBudget">
                            <span>
                        <strong>{{ calculateProgress(item.expenseBudget, item.totalBudget) }}%</strong></span
                      >
                        </b-progress-bar>
                    </b-progress>
                  </div>
                 
              </li>
            </ul>
          </div>
          <!-- Direct Factory Overheads (FOH) -->
          <div  v-show="showDfoData" class="target-content mt-5">
            <h2 class="content-title">Direct Factory Overheads (FOH)</h2>
            <ul>
              <li
                  v-for="item in DirectFactoryOverheads"
                  :key="item.id"
                  class="row w-100 m-0"
              >
                
                  <div class="l-label col-8">
                    <label>{{ item.name }}</label>
                  </div>
                  <div class="r-label col-4">
                    <span class="achieved h5">{{ item.expenseBudget }}</span> /
                    <span class="target h5">{{ item.totalBudget }}</span>
                  </div>
                  <div class="col-12">
                    <b-progress
                        :max="item.totalBudget"
                        height="25px"
                        variant="warning"
                        :precision="0"
                        show-progress="true"
                        class="mb-3 brd-15 ftb-15 w-100"
                    >
                        <b-progress-bar :value="item.expenseBudget">
                            <span>
                        <strong>{{ calculateProgress(item.expenseBudget, item.totalBudget) }}%</strong></span
                      >
                        </b-progress-bar>
                    </b-progress>
                  </div>
                 
              </li>
            </ul>
          </div>
           <!-- Indirect Factory Overheads (FOH) -->
           <div v-show="showIfoData" class="target-content mt-5">
                <h2 class="content-title">Indirect Factory Overheads (FOH)</h2>
                <ul>
                <li
                    v-for="item in DirectLabourCost"
                    :key="item.id"
                    class="row w-100 m-0"
                >
                    
                    <div class="l-label col-8">
                        <label>{{ item.name }}</label>
                    </div>
                    <div class="r-label col-4">
                        <span class="achieved h5">{{ item.expenseBudget }}</span> /
                        <span class="target h5">{{ item.totalBudget }}</span>
                    </div>
                    <div class="col-12">
                        <b-progress
                            :max="item.totalBudget"
                            height="25px"
                            variant="warning"
                            :precision="0"
                            show-progress="true"
                            class="mb-3 brd-15 ftb-15 w-100"
                        >
                            <b-progress-bar :value="item.expenseBudget">
                                <span>
                            <strong>{{ calculateProgress(item.expenseBudget, item.totalBudget) }}%</strong></span
                        >
                            </b-progress-bar>
                        </b-progress>
                    </div>
                    
                </li>
                </ul>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="c-card p-7">
          <div class="tableFixHead c_table">
            <b-table 
              :items="items" 
              :fields="fields"
              table-class="timeline-tbl" 
              responsive="sm" 
              borderless
              sticky-header
            >
              <template #cell(timeline)="row">
                  <strong size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.item.timeline }}
                  </strong>
              </template>
              
              <template #row-details="row">
                <b-card>
                  <template v-if="row.item.data">
                    <b-row>
                        <b-col md="3">
                          <strong size="sm" @click="row.toggleDetails" class="mr-2">
                            {{ row.item.timeline }}
                          </strong>
                        </b-col>
                        <b-col md="9">
                          <b-row v-for="item in row.item.data" :key="item.id" class="mb-2 text-center p-0">
                            <b-col md="9">{{ item.name }}</b-col>
                            <b-col md="3">{{ item.target }}</b-col>
                          </b-row>
                        </b-col>
                    </b-row>
                  </template>
                  <template v-if="!row.item.data">
                    <b-row>
                        <b-col md="3">
                          <strong size="sm" @click="row.toggleDetails" class="mr-2">
                            {{ row.item.timeline }}
                          </strong>
                        </b-col>
                        <b-col md="9">
                          <strong>Data not available</strong>
                        </b-col>
                    </b-row>
                  </template>
                  <b-row v-if="row.item.data" class="toggle_details_bottom pt-5">
                      <b-col md="3" class="text-center">
                        <span class="d-block"><strong>Total</strong></span>
                        <span class="d-block small">Yesterday</span>
                      </b-col>
                      <b-col md="3" offset-md="6" class="text-center">
                        <span class="d-block">
                          <strong>{{ row.item.amount }}</strong>
                        </span>
                      </b-col>
                    </b-row>
                </b-card>
              </template>
            </b-table>
          </div>
  
          <div class="summary-wrapper">
            <table class="summary-tbl">
              <thead>
              <tr>
                <th style="width: 36%;">{{ (filter.period == 'monthly')?filter.month+' '+filter.year: 'Q'+filter.quarter+' '+filter.year }}</th>
                <th style="width: 22%; text-align: center;">{{  incomeCalculate('new_patient') }}</th>
                <th style="width: 20%; text-align: center;">{{  incomeCalculate('patient_count') }}</th>
                <th style="width: 22%; text-align: right;">{{ numberFormat(incomeCalculate('total_income')) }}</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="width: 36%;">{{ (filter.period == 'monthly')?'Last Month': 'Last Quarter'}}</td>
                <td style="width: 22%; text-align: center;">
                    <span :class="calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) <= 0?'down-text':'up-text'"
                    ><i class="fas" :class="calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('new_patient'), lastMonthData.new_patient) }}%</span
                    >
                </td>
                <td style="width: 20%; text-align: center;">
                    <span :class="calculatePercentage(incomeCalculate('patient_count'), lastMonthData.patient_count) <= 0?'down-text':'up-text'"
                    ><i class="fas" :class="calculatePercentage(incomeCalculate('patient_count'), lastMonthData.patient_count) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('patient_count'), lastMonthData.patient_count) }}%</span
                    >
                </td>
                <td style="width: 22%; text-align: right;">
                  <span :class="calculatePercentage(incomeCalculate('total_income'), lastMonthData.total_income) <= 0?'down-text':'up-text'"
                    ><i class="fas" :class="calculatePercentage(incomeCalculate('total_income'), lastMonthData.total_income) <= 0?'fa-chevron-down':'fa-chevron-up'"></i> {{ calculatePercentage(incomeCalculate('total_income'), lastMonthData.total_income) }}%</span
                    >
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "axios";
  import moment from "moment";
  
  export default {
    name: "CenterReportView",
    data() {
      let all_months = [
      {
          text: "Q1",
          value: "1"
        },
        {
          text: "Jan",
          value: "January",
        },
        {
          text: "Feb",
          value: "February",
        },
        {
          text: "Mar",
          value: "March",
        },
        {
          text: "Q2",
          value: "2"
        },
        {
          text: "Apr",
          value: "April",
        },
        {
          text: "May",
          value: "May",
        },
        {
          text: "Jun",
          value: "June",
        },
        {
          text: "Q3",
          value: "3"
        },
        {
          text: "Jul",
          value: "July",
        },
        {
          text: "Aug",
          value: "August",
        },
        {
          text: "Sep",
          value: "September",
        },
        {
          text: "Q4",
          value: "4"
        },
        {
          text: "Oct",
          value: "October",
        },
        {
          text: "Nov",
          value: "November",
        },
        {
          text: "Dec",
          value: "December",
        },
      ];
      let costs_list = [
        {   
            id: 511,
            name: 'Direct Material Consumed',
            target: '70',
            targetTotal: '100',
        },
        {
            name: 'Direct Labour Cost',
            target: '15',
            targetTotal: '100'
        },
        {
            name: 'Direct Factory Overheads (FOH)',
            target: '25',
            targetTotal: '100'
        },
        {
            name: 'Indirect Factory Overheads (FOH)	',
            target: '40',
            targetTotal: '100'
        },
      ];
      let directMaterialConsumData = [
            {
                id: 51110,
                name: 'Raw Material Consumed - HVFB',
                expenseBudget: '30',
                totalBudget: '100',
            },
            {
                id: 51111,
                name: 'Raw Material Consumed - Tea Lounge',
                expenseBudget: '30',
                totalBudget: '100',
            },
            {
                id: 51112,
                name: 'Packaging Material Consumed - HVFB',
                expenseBudget: '30',
                totalBudget: '100',
            },
            {
                id: 51113,
                name: 'Packaging Material Consumed - Tea Lounge',
                expenseBudget: '30',
                totalBudget: '100',
            },
            {
                id: 51114,
                name: 'Raw Material Quarried - Opening Balance',
                expenseBudget: '30',
                totalBudget: '100',
            },
      ];
      let DirectLabourCost = [
            {
                id: 51110,
                name: 'Raw Material Consumed - HVFB',
                expenseBudget: '30',
                totalBudget: '100',
            },
            {
                id: 51111,
                name: 'Raw Material Consumed - Tea Lounge',
                expenseBudget: '30',
                totalBudget: '100',
            }
      ];
      let DirectFactoryOverheads = [
            {
                id: 51110,
                name: 'Raw Material Consumed - HVFB',
                expenseBudget: '30',
                totalBudget: '100',
            },
            {
                id: 51111,
                name: 'Raw Material Consumed - Tea Lounge',
                expenseBudget: '30',
                totalBudget: '100',
            }
      ];
      let IndirectFactoryOverheads = [
            {
                id: 51110,
                name: 'Raw Material Consumed - HVFB',
                expenseBudget: '30',
                totalBudget: '100',
            },
            {
                id: 51111,
                name: 'Raw Material Consumed - Tea Lounge',
                expenseBudget: '30',
                totalBudget: '100',
            }
      ];
      return {
        loading: false,
        value: 88,
        max: 100,
        months: all_months,
        filter: {
          period: 'monthly',
          month: '',
          center: '',
          year: 2023,
          quarter: '',
        },
        costFilter: [
            {
                name: 'Direct Material Consumed',
                value: 'direct_material'
            },
            {
                name: 'Direct Labour Cost',
                value: 'labour_cost'
            },
            {
                name: 'Direct Factory Overheads (FOH)',
                value: 'factory_overheads'
            }
        ],
        month: all_months[new Date().getMonth()].value,
        year: "2023",
        table_data: [
          {   
              id: 511,
              name: 'Direct Material Consumed',
              target: '70',
              targetTotal: '100',
          },
          {
              name: 'Direct Labour Cost',
              target: '15',
              targetTotal: '100'
          },
          {
              name: 'Direct Factory Overheads (FOH)',
              target: '25',
              targetTotal: '100'
          },
          {
              name: 'Indirect Factory Overheads (FOH)	',
              target: '40',
              targetTotal: '100'
          },
        ],
        detailsRow: null,
        fields: [
            {
              key: 'timeline',
              label: 'Timeline'
            },
            {
              key: 'cost_head',
              label: 'Cost Head'
            },
            {
              key: 'amount',
              label: 'Amount'
            },
          ],
        items: [
          { 
            isActive: false, 
            timeline: '10/12/23', 
            cost_head: 'All Expenses', 
            amount: 1000,
            
          },
          { 
            isActive: false, 
            timeline: '11/12/23', 
            cost_head: 'All Expenses', 
            amount: 10000,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '7000',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '1000',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '1000',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '1000',
              },
            ]
          },
          { 
            isActive: false, 
            timeline: '12/12/23', 
            cost_head: 'All Expenses', 
            amount: 100000,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '50000',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '20000',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '25000',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '5000',
              },
            ]
          },
          { 
            isActive: false, 
            timeline: '13/12/23', 
            cost_head: 'All Expenses', 
            amount: 50000,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '10000',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '15000',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '10000',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '15000',
              },
            ]
          },
          { 
            isActive: false, 
            timeline: '14/12/23', 
            cost_head: 'All Expenses', 
            amount: 20000,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '5000',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '3000',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '5000',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '7000',
              },
            ]
          },
          { 
            isActive: false, 
            timeline: '15/12/23', 
            cost_head: 'All Expenses', 
            amount: 30000,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '10000',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '5000',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '5000',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '10000',
              },
            ]
          },
          { 
            isActive: false, 
            timeline: '16/12/23', 
            cost_head: 'All Expenses', 
            amount: 80000,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '40000',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '20000',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '10000',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '10000',
              },
            ]
          },
          { 
            isActive: false, 
            timeline: '17/12/23', 
            cost_head: 'All Expenses', 
            amount: 10,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '70',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '15',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '25',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '40',
              },
            ]
          },
          { 
            isActive: false, 
            timeline: '18/12/23', 
            cost_head: 'All Expenses', 
            amount: 10,
            data: [
              {   
                  id: 511,
                  name: 'Direct Material Consumed',
                  target: '70',
              },
              {
                  name: 'Direct Labour Cost',
                  target: '15',
              },
              {
                  name: 'Direct Factory Overheads (FOH)',
                  target: '25',
              },
              {
                  name: 'Indirect Factory Overheads (FOH)	',
                  target: '40',
              },
            ]
          },
          
        ],
        services: [],
        targets: [],
        lastMonthData: {},
        costs_list,
        directMaterialConsumData,
        DirectFactoryOverheads,
        IndirectFactoryOverheads,
        DirectLabourCost,
        showMonthFilter: false,
        showCenterFilter: false,
        showPatientFilter: false,
        showAllData: true,
        showDmcData: false,
        showDlcData: false,
        showIfoData: false,
        showDfoData: false,
        allExpense: false,
        centers: [],
      };
    },
    created() {
      this.filter.month = moment().format('MMM'),
      this.getReports();
      this.centerList();
      localStorage.setItem('tabIndex', 16)
      this.$root.$emit('call-active-menu', 16);
    },
    methods: {
      onRowClicked(item) {
      const { detailsRow } = this
      if (detailsRow && detailsRow !== item) {
        detailsRow._showDetails = false;
      }

      this.$set(item, "_showDetails", !item._showDetails);
      this.detailsRow = item;
    },
      selectPeriod(type, value) {
        this.filter.period = type;
        if(type == 'monthly') {
          this.filter.quarter = '';
          this.filter.month = value;
        } else {
          this.filter.month = '';
          this.filter.quarter = value;
        }
      },
      numberFormat(num) {
        return num?(Math.round(num * 100) / 100).toFixed(2):0;
      },
      calculateProgress(achieved, target) {
        if(target > 0) {
          return Math.floor((achieved*100)/target);
        }
        return 0;
      },
      checkTargetExists(product) {
        return this.targets.some(item => item.product_type == product);
      },
      getTarget(product, fieldName) {
        let target = this.targets.find(item => item.product_type == product);
        if(target) {
          return target[fieldName];
        }
        return 0;
      },
      handleRowClicked(index, date) {
        this.loading = true;
        let selectedCenter = null;
        if(this.filter.center) {
          selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
        }
  
        axios
            .get(
                `${
                    process.env.VUE_APP_API_URL
                }/api/v2/report/orkocenter/occ/service-detail/by-date?type=center&api_token=${localStorage.getItem(
                    "api_token"
                )}${selectedCenter?'&institute_id='+selectedCenter.id:''}
                &date=${date}&period=${this.filter.period}`
            )
            .then((res) => {
              this.table_data = this.table_data.map(((item, key) => {
                            if(index == key) {
                              item.expand = !item.expand;
                              item.services = res.data.data;
                              item.prev_day = res.data.prev_day;
                            } else {
                              item.expand = false;
                            }
                            return item;
                          }));
  
  
              this.loading = false;
            });
  
      },
      calculatePercentage(currentMonth, prevMonth) {
        prevMonth = prevMonth?prevMonth:0;
        let diff = currentMonth - prevMonth;
        if(diff != 0 && prevMonth != 0) {
          return Math.round((diff / prevMonth * 100) * 100) / 100
          // return Math.floor(diff / prevMonth * 100);
        } else if (diff != 0 && prevMonth == 0) {
          return Math.round(diff * 100) / 100
          // return Math.floor(diff);
        } else {
          return 0;
        }
      },
      incomeCalculate(type) {
        let total = 0;
        this.table_data.forEach((item) => {
          total += item[type]
        });
        return total;
      },
      serviceTotal(type) {
        let total = 0;
        this.services.forEach((item) => {
          if(type == 'total_income' && item.product_type == 'Dental') {
            total += Math.round(item[type]*15/100)
          } else {
            total += item[type]
          }
        });
        return total;
      },
      targetTotal(type) {
        let total = 0;
        this.targets.forEach((item) => {
          total += item[type]
        });
        return total;
      },
      dateFormat(date) {
        if(this.filter.period == 'monthly') {
          return date?moment(date).format('D MMM'):'';
        } else {
          return date?moment(date).format('MMM YYYY'):'';
        }
      },
      selectFilter(type) {
        if (type == "Month") {
          this.showCenterFilter = false;
          this.showMonthFilter = !this.showMonthFilter;
        } else if (type == "Center") {
          this.showMonthFilter = false;
          this.showCenterFilter = !this.showCenterFilter;
        }
      },
      getReports() {
        this.loading = true;
        let selectedCenter = null;
        if(this.filter.center) {
          selectedCenter = this.centers.find(item => item.short_form == this.filter.center);
        }
        axios
            .get(
                `${
                    process.env.VUE_APP_API_URL
                }/api/v2/report/orkocenter/occ/center?api_token=${localStorage.getItem(
                    "api_token"
                )}${this.filter.month?'&month='+this.filter.month:''}${selectedCenter?'&institute_id='+selectedCenter.id:''}
                &period=${this.filter.period}&year=2023${this.filter.quarter?'&quarter='+this.filter.quarter:''}`
            )
            .then((res) => {
              this.table_data = res.data.data.data.reverse();
              this.table_data = this.table_data.map((item => {
                item.prev_day = {};
                item.services = [];
                if(moment(item.date).isSame(moment(), 'day')) {
                  item.expand = false; //TODO
                  item.isToday = true;
                } else {
                  item.expand = false;
                  item.isToday = false;
                }
                return item;
              }))
  
              this.services = res.data.service_counts;
              this.lastMonthData = res.data.last_month;
              this.targets = res.data.target_counts;
              this.loading = false;

            });
      },
      centerList() {
        axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
            .then(response => {
              if (response.data.status_code == '500') {
                this.$router.push('/access-denied')
              } else {
                // this.loading = false
                this.centers = response.data.data
              }
            })
      },
      calculateDentalOrko(services){
        const serviceObj = services.find(item => item.product_type == 'Dental');
        if(serviceObj){
          return serviceObj.total_income?((serviceObj.total_income) * (15/100)).toFixed(2) :0 ;
        }
        return 0;
        // console.log('serviceObj', serviceObj.total_income);
      }
    },
  };
  </script>
  <style lang="scss">
    .c_table{
      .b-table-sticky-header{
        max-height: 100%;
      }
      .b-table-sticky-header > .table.b-table > thead > tr > th{
        z-index: auto;
      }
      .timeline-tbl {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        float: left;
        .expanded-row {
          box-shadow: 0 0 0 1px #AAA;
          border-radius: 20px;
        }
  
        tr {
          th {
            color: #002147;
            border-bottom: 1px solid #a0a0a1;
            padding-bottom: 10px;
            text-align: center;
            font-size: 16px;
          }
        }
    
        tbody {
          tr {
            &.b-table-has-details{
              display: none;
            }
            &.b-table-details .card{
              box-shadow: 0 0 0 1px #AAA;
              border-radius: 20px;

              .toggle_details_bottom{
                border-top: 1px solid #aaa;
                margin: 0 -30px;
                span{
                  line-height: 1.3;
                }
              }
            }
            td {
              text-align: center;
              font-size: 16px;
              line-height: 2.5;
              color: #002170;
            }
    
            &:first-of-type {
              td {
                padding-top: 10px;
              }
            }
          }
        }
    }
    }
  </style>
  <style lang="scss" scoped>
  .c-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 15px; /* 5px rounded corners */
    min-height: 200px;
    background-color: #fff;
    width: 100%;
    float: left;
  
    .filter-list {
      width: 100%;
      float: left;
      list-style-type: none;
      margin-top: 20px;
      margin-bottom: 20px;
  
      li {
        width: 50%;
        float: left;
        padding: 0px 10px 0;
        border-right: 2px solid #002170;
        text-align: center;
  
        &:last-of-type {
          border-right: none;
          padding: 0;
        }
  
        select {
          border: none;
          outline: none;
          scroll-behavior: smooth;
          -webkit-appearance: listbox !important;
  
          color: #002170;
          font-size: 15px;
          font-weight: 600;
          font-family: Arial;
          padding: 0 12px 0 8px;
          height: 22px;
        }
      }
    }
  
    .target-content {
      width: 100%;
      float: left;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      transition: 0.3s;
      border-radius: 15px; /* 5px rounded corners */
      min-height: 200px;
      background-color: #004E3F;
      padding: 25px;
  
      h2 {
        color: #FFD600;
        font-family: Arial;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 15px;
      }
  
      ul {
        list-style-type: none;
        margin-bottom: 20px;
        width: 100%;
        float: left;
  
        li {
          width: 100%;
          float: left;
  
         
          .l-label {
            width: 45%;
            float: left;
          }
  
          .r-label {
            text-align: right;
            color: #fff;
            font-weight: 600;
            font-size: 18px;
            font-family: Arial;
  
            .achieved {
              color: #f9a91a;
            }
          }
  
          label {
            color: #fff;
            font-size: 18px;
            font-family: Arial;
            font-weight: 600;
          }
  
          &.total {
            label {
              font-size: 20px;
            } 
            .r-label {
              font-size: 20px;
                .achieved {
                  color: #44B3E6;
                }
              }
              .progress-bar {
                background-color: #44B3E6 !important;
              }
          }
        }
      }
    }
  
    .timeline-tbl {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      float: left;
      .expanded-row {
        box-shadow: 0 0 0 1px #AAA;
        border-radius: 20px;
      }
  
      tr {
        th {
          color: #002147;
          border-bottom: 1px solid #a0a0a1;
          padding-bottom: 10px;
          text-align: center;
          font-size: 16px;
        }
      }
  
      tbody {
        tr {
          &.expanded-total-row {
            border-top: 1px solid #AAA;
           td {
              ul {
                margin: 6px 0;
                list-style-type: none;
                li {
                  line-height: 1.6;
                  &:last-of-type {
                    font-size: 13px;
                    span {
                      font-size: 13px;
                      i {
                        font-size: 13px;
                      }
                    }
                  }
                }
              }
           }
          }
          td {
            text-align: center;
            font-size: 16px;
            line-height: 2.5;
            color: #002170;
          }
  
          &:first-of-type {
            td {
              padding-top: 10px;
            }
          }
        }
      }
    }
  
    .summary-wrapper {
      width: 100%;
      float: left;
      background-color: #004E3F;
      padding: 8px 30px;
      border-radius: 20px;
      margin-top: 30px;
  
      .summary-tbl {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        float: left;
  
        tr {
          th {
            color: #fff;
            font-size: 17px;
            padding-bottom: 5px;
          }
  
          td {
            font-size: 15px;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }
  .brd-15 {
    border-radius: 15px;
  }

  @media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition-property: height, visibility;
        transition-duration: .35s;
    }
}
  
  .ftb-15 {
    font-size: 15px;
    font-weight: 600;
  }
  
  .up-text {
    color: #00cd32;
    font-size: 15px;
    font-weight: 600;
  
    i {
      color: #00cd32;
      font-size: 13px;
    }
  }
  
  .down-text {
    color: red;
    font-size: 15px;
    font-weight: 600;
  
    i {
      color: red;
      font-size: 13px;
    }
  }
  
  .coll-btn {
    color: #002170;
    font-size: 15px;
    font-weight: 600;
    font-family: Arial;
    padding: 0 12px 0 8px;
    height: 22px;
  
    background-color: transparent;
    border: none;
  
    &:active {
      background-color: transparent !important;
    }
  
    &:focus {
      background-color: transparent !important;
    }
  }
  
  .month-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;
  
    li {
      width: 25%;
      float: left;
      text-align: center;
      margin-bottom: 20px;
  
      a {
        background-color: #EBEBEB;
        padding: 7px 25px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;
  
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
  
      &:nth-child(1), &:nth-child(5), &:nth-child(9), &:nth-child(13) {
        a {
          background-color: #AAAAAA;
          color: #fff;
          &.active {
            background-color: #F9A81A;
            font-weight: 600;
          }
        }
      }
    }
  }
  

  .center-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;
    padding: 10px;
    
    li {
      width: auto;
      margin: 0 3px;
      float: left;
      text-align: center;
      margin-bottom: 20px;
  
      a {
        background-color: #EBEBEB;
        padding: 7px 25px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;
  
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
  
  .patient-selection-list {
    width: 100%;
    float: left;
    list-style-type: none;
    font-family: Arial;
    font-size: 16px;
    margin-top: 20px;
  
    li {
      width: 50%;
      float: left;
      text-align: center;
      margin-bottom: 20px;
  
      a {
        background-color: #EBEBEB;
        padding: 8px 30px;
        border-radius: 20px;
        color: #000;
        font-family: Arial;
  
        &.active {
          background-color: #F9A81A;
          font-weight: 600;
        }
      }
    }
  }
  
  
  .tableFixHead {
    overflow: auto;
    height: 500px;

    &.c_table{
      overflow: hidden;
    }
  }
  
  .tableFixHead thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
  }
  
  .patient-income-table {
    height: 475px;
    overflow: scroll;
    // padding: 2px;
  }
  </style>
  